export default [
  {
    header: 'Configuraciones',
    icon: 'ToolIcon',
    children: [
      {
        title: 'Configurar Cursos y Asignaturas',
        route: 'cursos',
        icon: 'ToolIcon',
      },
      {
        title: 'Configurar Promedios',
        route: 'promedios',
        icon: 'ToolIcon',
      },
    ],
  },
]
