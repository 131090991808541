export default [
  {
    header: 'Inicio',
    title: 'Inicio',
    route: 'inicio',
    icon: 'HomeIcon',
    // action: 'read',
    // resource: 'home',
  },
]
