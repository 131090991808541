export default [
  {
    header: 'Mantenedores',
    icon: 'BoxIcon',
    children: [
      {
        title: 'Periodos',
        route: 'periodos',
        icon: 'ClockIcon',
      },
      {
        title: 'Sostenedores',
        route: 'sostenedores',
        icon: 'AwardIcon',
      },
      {

        title: 'Establecimientos',
        route: 'establecimientos',
        icon: 'HomeIcon',
      },
      // {
      //   title: 'Calendario Escolar',
      //   route: 'calendarios',
      //   icon: 'CalendarIcon',
      // },
      // {
      //   title: 'Carga Horaria',
      //   route: 'horarios',
      //   icon: 'CalendarIcon',
      // },
      {
        title: 'Alumnos',
        route: 'alumnos',
        icon: 'UsersIcon',
      },
      {
        title: 'Cuentas de Apoderados',
        route: 'apoderados',
        icon: 'UserIcon',
      },
      {
        title: 'Cuentas de Usuarios',
        route: 'usuarios',
        icon: 'UserIcon',
      },
    ],
  },
]
